/**
 *  z241843 Viswa Achari
 *  CMS (WCM) Migration to Adobe (AEM)
 */

$(document).ready(function () {

    'use strict';

    if (!window.CVS) {
        window.CVS = { 
            Config: {
                parentHost: {   //Service Domain URL's configuration for host name's
                    stp: "https://stp.cvsspecialty.com/api/",
                    dev1: "https://dev1www.cvsspecialty.com/api/",
                    dev2: "https://dev2www.cvsspecialty.com/api/",
                    dev3: "https://dev3www.cvsspecialty.com/api/",
                    sit1: "https://sit1www.cvsspecialty.com/api/",
                    sit2: "https://sit2www.cvsspecialty.com/api/",
                    sit3: "https://sit3www.cvsspecialty.com/api/",
                    prod: "https://www.cvsspecialty.com/api/",
                    local: "http://localhost:8080/api/"
                },
                envConfig: {
                    stp: {
                        apiurl: "https://stp.cvsspecialty.com/",
                        xAPIKey: 'fCR9A9vzJyfsCKBYk3xvGnZ3bK9A7hDq'
                    },
                    dev1: {
                        apiurl: "https://dev1www.cvsspecialty.com/",
                        xAPIKey: 'fCR9A9vzJyfsCKBYk3xvGnZ3bK9A7hDq'
                    },
                    dev2: {
                        apiurl: "https://dev2www.cvsspecialty.com/",
                        xAPIKey: 'fCR9A9vzJyfsCKBYk3xvGnZ3bK9A7hDq'
                    },
                    dev3: {
                        apiurl: "https://dev3www.cvsspecialty.com/",
                        xAPIKey: 'fCR9A9vzJyfsCKBYk3xvGnZ3bK9A7hDq'
                    },
                    sit1: {
                        apiurl: "https://sit1www.cvsspecialty.com/",
                        xAPIKey: 'fCR9A9vzJyfsCKBYk3xvGnZ3bK9A7hDq'
                    },
                    sit2: {
                        apiurl: "https://sit2www.cvsspecialty.com/",
                        xAPIKey: 'fCR9A9vzJyfsCKBYk3xvGnZ3bK9A7hDq'
                    },
                    sit3: {
                        apiurl: "https://sit3www.cvsspecialty.com/",
                        xAPIKey: 'fCR9A9vzJyfsCKBYk3xvGnZ3bK9A7hDq'
                    },
                    prod: {
                        apiurl: "https://www.cvsspecialty.com/",
                        xAPIKey: '2O6FLB5VZGqLdoMUsfPCLGySGfYzAfTE'
                    },
                    local: {
                        apiurl: "http://localhost:8080/",
                        xAPIKey: 'fCR9A9vzJyfsCKBYk3xvGnZ3bK9A7hDq'
                    }
                },
                services: {   //Web Service seriveKey name's
                    TOKEN_EXPIRE: { URL: "token/expire/V1", serviceName: "tokenExpire" },
                    GET_TOKENID: { URL: "userAuthentication/getTokenID/v1", serviceName: "getTokenID" },
                },
                apiKey: {   //Config Keys for prod and for all other lower env(SIT,DEV,STP) non-prod has same Key.
                    prod: "ad4154dd-88b4-451c-81a2-4fa49da9cdba",
                    nonProd: "d489fa90-8423-4db8-a11f-ff8f29ebdb19"
                }
            },
            oidcIDToken: '',
            oidcRefreshTimeout: null
        };
    }

    /**
     * Function to call genric AGP serives which was configured in Vordel
     */

    function getAGPApiUrl(serviceKey) {
        var env = getEnvironment();
        var SPLAGP = "SPLAGPv1/";
        return CVS.Config.parentHost[env] + SPLAGP + CVS.Config.services[serviceKey].URL;
    }


    /**
     * Function to call generic specialty serives 
     */

    function getApiUrl(serviceKey) {
        var env = getEnvironment();
        var specialty = "specialty/";
        return CVS.Config.parentHost[env] + specialty + CVS.Config.services[serviceKey].URL;
    }

    function getApiKey() {
        var env = getEnvironment();
        if (env == 'prod') {
            return CVS.Config.apiKey['prod'];
        } else {
            return CVS.Config.apiKey['nonProd'];
        }
    }

    /**
     *  @param {*} requestBody 
     *  getTokenID requestbody
     */
    var getTokenReqBody = function () {

        var apikey = getApiKey();

        var reqObj = {
            "getTokenIDRequest": {
                "header": {
                    "serviceContext": {
                        "appName": false ? 'SPL_APP' : 'SPL_WEB',
                        "channelName": 'Web',// ToDo : make this logic dynamic
                        "lineOfBusiness": "Specialty",
                        "responseFormat": "JSON"
                    },
                    "securityContext": {
                        "apiKey": apikey,
                        "securityType": "apiKey"
                    }
                },
                "details": {}
            }
        };
        return reqObj;
    }

    /**
     *  Service call for getTokenID Service  
     *  @param {*} requestBody 
     */
    function getTokenIdService(requestBody) {

        var url = getAGPApiUrl('GET_TOKENID');

        $.ajax({
            type: "POST",
            url: url,
            data: requestBody,
            dataType: "json",
            contentType: "application/json",
            beforeSend: function (xhr) {
                xhr.setRequestHeader('Accept', 'application/json');
            },
            success: function (data) {
                if (data && data.hasOwnProperty('getTokenIDResponse')) {
                    if (data.getTokenIDResponse.hasOwnProperty('header')) {
                        if (data.getTokenIDResponse.header.statusCode == '0000') {
                            var tokenID = data.getTokenIDResponse.details.tokenID;
                            var request = tokenExpireBody(tokenID);
                            tokenExpireService(request)
                        } else {
                            fastLogOut();
                        }
                    } else {
                        fastLogOut();
                    }
                } else {
                    fastLogOut();
                }
            },
            error: function () {
                fastLogOut();
            }
        });
    }

    /**
     *  @param {*} requestBody
     *  tokenExpireService requestbody 
     */

    var tokenExpireBody = function (tokenID) {

        var apikey = getApiKey();

        var reqObj = {
            "tokenExpireRequest": {
                "header": {
                    "serviceContext": {
                        "appName": false ? 'SPL_APP' : 'SPL_WEB', // cvsUtility.queryMap.mobileAppStyle
                        "lineOfBusiness": "Specialty",
                        "channelName": 'Web', // ToDo : Make this logic dynamic : cvsUtility.queryMap.channel
                        "responseFormat": "XML",
                        "tokenID": tokenID,
                        "tokenType": "Login"
                    },
                    "securityContext": {
                        "apiKey": apikey,
                        "securityType": "apiKey"
                    }
                }
            }
        };

        var x2js = new X2JS();
        return x2js.json2xml_str(reqObj);
    }

    /**
     *  Service call for token/expire serive  
     *  ex: https://sit2www.cvsspecialty.com/api/specialty/token/expire/V1
     */

    function tokenExpireService(data) {

        var url = getApiUrl('TOKEN_EXPIRE');

        $.ajax({
            type: "POST",
            url: url,
            data: data,
            dataType: "xml",
            contentType: "application/xml",
            beforeSend: function (xhr) {
                xhr.setRequestHeader('Accept', 'application/xml');
            },
            success: function (data) {
                console.log(data);
                window.location.href = "/wps/portal/specialty?logoff=true";
                fastLogOut();
            },
            error: function () {
                fastLogOut();
            }
        });

    }
    
    function oidcKeycloakConfig(){
        const env = getEnvironment();
        return env ? CVS.Config.envConfig[env]: CVS.Config.envConfig['prod'];
    }

    function oidcLogOut() {
        const envConfig = oidcKeycloakConfig();
        const logoutUrl = `${envConfig.apiurl}apims/specialty/userservice/v3/logout`;
        $.ajax({
            type: "DELETE",
            url: logoutUrl,
            dataType: "json",
            contentType: "application/json",
            beforeSend: function (xhr) {
                xhr.setRequestHeader('x-api-key', envConfig.xAPIKey);
            },
            success: function () {
                fastLogOut();
            },
            error: function () {
                fastLogOut();
            }
        });
    }

    function oidcRefreshToken() {
        const ssoLoginFlow = getCookie('SSO_Login');
        if(!!ssoLoginFlow){
            const envConfig = oidcKeycloakConfig();
            const tokenUrl = `${envConfig.apiurl}apims/specialty/userservice/v3/refreshtoken`;
            $.ajax({
                type: "GET",
                url: tokenUrl,
                dataType: "json",
                contentType: "application/json",
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('x-api-key', envConfig.xAPIKey);
                },
                success: function (data) {
                    if (data && data.hasOwnProperty('tokenID')) {
                        document.cookie = `expire_ttl=${data.exp};path=/`;
                        clearTimeout(CVS.oidcRefreshTimeout);
                        var dateObj = new Date(data.exp * 1000);
                        var diffTime = this.differentTimeStamp(new Date(), dateObj);
                        if(!data.exp || diffTime > 3000){
                            CVS.oidcRefreshTimeout = setTimeout(()=> oidcRefreshToken(), diffTime - 120000);
                        } else {
                            fastLogOut();
                        }
                    }
                },
                error: function () {
                    fastLogOut();
                }
            });
        }
        
    }
    /**
     *  AEM will trigger this function on click of sign out in Home page
     */
    CVS.aemLogout = function () {
        console.log("aem static file got triggered ");
        const ssoLoginFlow = getCookie('SSO_Login');
        if(!!ssoLoginFlow){
            oidcLogOut();
        } else {            
            var requestBody = JSON.stringify(getTokenReqBody());
            getTokenIdService(requestBody);
        }

    }
    function getCookie(cookieName) {
        const cookieArr = document.cookie.match(
            '(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)'
        );
        return cookieArr ? cookieArr.pop() : '';
    }   

    /**
     *  Make the cookie empty if gettokenId/TokenExpire serive is failed
     */

    function fastLogOut() {
        eraseCookie('fastToken');
        eraseCookie('SPLTY_TOKEN_ID');
        eraseCookie('careTeamCookie');
        eraseCookie('SSO_Login');
        window.location.href = "/wps/portal/specialty?logoff=true";
    }


    function eraseCookie(name) {
        createCookie(name, "", -1);
    }

    function createCookie(name, value, days) {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            var expires = "; expires=" + date.toGMTString();
        }
        else expires = "";
        document.cookie = name + "=" + value + expires + "; path=/";
    }
    function decodeOIDCToken(str) {
        if(!!str){
          str = str.split('.')[1];
    
          str = str.replace(/-/g, '+');
          str = str.replace(/_/g, '/');
          switch (str.length % 4) {
              case 0:
                  break;
              case 2:
                  str += '==';
                  break;
              case 3:
                  str += '=';
                  break;
              default:
                  throw 'Invalid token';
          }
      
          str = decodeURIComponent(escape(atob(str)));
      
          str = JSON.parse(str);
          return str;
        }    
      }
    /**
     *  pass the dynamic host name  to env for genric AGP/ Specialty service based on the doamnin host name
     */

    function getEnvironment() {
        var host = window.location.hostname;
        console.log(host);
        var hostValue = "";
        if (host !== 'localhost') {
            hostValue = host.substr(0, host.indexOf('.'));
        } else {
            hostValue = host;
        }
        var env = "";
        switch (hostValue) {
            case "localhost": env = "local";
                break;
            case "dev1www": env = "dev1"
                break;
            case "dev2www": env = "dev2"
                break;
            case "dev3www": env = "dev3"
                break;
            case "sit1www": env = "sit1"
                break;
            case "sit2www": env = "sit2"
                break;
            case "sit3www": env = "sit3"
                break;
            case "stp": env = "stp"
                break;
            case "www": env = "prod"
                break;
        }
        return env;
    }
    
    const RefreshToken = ()=>{
        var expireTtl = getCookie('expire_ttl');
        var tokenId = getCookie('SPLTY_TOKEN_ID');
        var dateObj = new Date(expireTtl * 1000);
        var diffTime = this.differentTimeStamp(new Date(), dateObj);
        if(!!tokenId || diffTime > 3000){
          var dateObj = new Date(expireTtl * 1000);
          var diffTime = this.differentTimeStamp(new Date(), dateObj)
          console.log(diffTime, 'diff time');
          CVS.oidcRefreshTimeout = setTimeout(()=> oidcRefreshToken(), diffTime - 120000 );
        }
    };
    RefreshToken();
    

    const differentTimeStamp = (startDate, endDate)=>{
        return (endDate.getTime() - startDate.getTime());
    }
});
